@charset "UTF-8";

@font-face {
    font-style: normal;
    font-weight: normal;
    font-family: 'airFont';
    src: url('../assets/fonts/air-font/air-font.eot');
    src: url('../assets/fonts/air-font/air-font.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/air-font/air-font.woff') format('woff'),
        url('../assets/fonts/air-font/air-font.ttf') format('truetype'),
        url('../assets/fonts/air-font/air-font.svg#air-font') format('svg');
}

.icon {
    text-align: center;
    text-rendering: auto;
    display: inline-block;
    vertical-align: middle;
    text-transform: initial;
    font: normal normal normal 16px/1 airFont, air-font;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: inherit;
}

.icon-user:before {
    content: "\e900";
}

.icon-baby:before {
    content: "\e901";
}

.icon-language:before {
    content: "\e914";
}

.icon-rings:before {
    content: "\e915";
}

.icon-users:before {
    content: "\e916";
}

.icon-like:before {
    content: "\e9d9";
}

.icon-dislike:before {
    content: "\e9da";
}

.icon-email:before {
    content: "\e928";
}

.icon-phone:before {
    content: "\e91e";
}

.icon-hair:before {
    content: "\26";
}

.icon-health:before {
    content: "\27";
}

.icon-hourglass:before {
    content: "\29";
}

.icon-kids:before {
    content: "\2e";
}

.icon-life-family:before {
    content: "\2f";
}

.icon-0-9:before {
    content: "\30";
}

.icon-fashion:before {
    content: "\33";
}

.icon-feed:before {
    content: "\36";
}

.icon-fitness:before {
    content: "\38";
}

.icon-gastronomy:before {
    content: "\3a";
}

.icon-general-posts:before {
    content: "\3b";
}

.icon-lifestyle:before {
    content: "\3c";
}

.icon-construction:before {
    content: "\4d";
}

.icon-create-campaign:before {
    content: "\4e";
}

.icon-culture:before {
    content: "\4f";
}

.icon-education:before {
    content: "\56";
}

.icon-environment:before {
    content: "\57";
}

.icon-music:before {
    content: "\59";
}

.icon-activism:before {
    content: "\63";
}

.icon-air-share:before {
    content: "\6d";
}

.icon-automotive:before {
    content: "\78";
}

.icon-business:before {
    content: "\7a";
}

.icon-aviation:before {
    content: "\7c";
}

.icon-beauty:before {
    content: "\7e";
}

.icon-beer:before {
    content: "\7f";
}

.icon-nutrition:before {
    content: "\8f";
}

.icon-nutrition-copy:before {
    content: "\90";
}

.icon-next1:before {
    content: "\104";
}

.icon-message:before {
    content: "\152";
}

.icon-martial-art:before {
    content: "\160";
}

.icon-padlock:before {
    content: "\2013";
}

.icon-pet:before {
    content: "\2018";
}

.icon-link-network:before {
    content: "\2020";
}

.icon-literature:before {
    content: "\2021";
}

.icon-menu-burger:before {
    content: "\2039";
}

.icon-pinterest:before {
    content: "\e000";
}

.icon-politic:before {
    content: "\e003";
}

.icon-refresh:before {
    content: "\e00b";
}

.icon-sports:before {
    content: "\e01c";
}

.icon-stamp-airscore:before {
    content: "\e01d";
}

.icon-stories:before {
    content: "\e01e";
}

.icon-symbol-airfluencers1:before {
    content: "\e01f";
}

.icon-reach:before {
    content: "\e022";
}

.icon-retweet:before {
    content: "\e024";
}

.icon-share-fb:before {
    content: "\e027";
}

.icon-technology:before {
    content: "\e02b";
}

.icon-topic-audiovisual:before {
    content: "\e02d";
}

.icon-topic-entertainment1:before {
    content: "\e02e";
}

.icon-topics:before {
    content: "\e02f";
}

.icon-travel:before {
    content: "\e031";
}

.icon-views:before {
    content: "\e033";
}

.icon-interaction-text:before {
    content: "\e036";
}

.icon-others:before {
    content: "\e037";
}

.icon-social-youtube-circle1:before {
    content: "\e04e";
}

.icon-topic-finances:before {
    content: "\e09f";
}

.icon-topic-spirituality:before {
    content: "\e902";
}

.icon-topic-communication:before {
    content: "\e903";
}

.icon-topic-agribusiness:before {
    content: "\e904";
}

.icon-clear:before {
    content: "\e905";
}

.icon-pink-star:before {
    content: "\e906";
}

.icon-tiktok1:before {
    content: "\e907";
}

.icon-outline-comment:before {
    content: "\e908";
}

.icon-engagement:before {
    content: "\e909";
}

.icon-alert:before {
    content: "\e90a";
}

.icon-9:before {
    content: "\e90b";
}

.icon-a-z:before {
    content: "\e90c";
}

.icon-add-influencer:before {
    content: "\e90d";
}

.icon-add-multiple:before {
    content: "\e90e";
}

.icon-advance:before {
    content: "\e90f";
}

.icon-air-symbol:before {
    content: "\e910";
}

.icon-airscore-leak:before {
    content: "\e911";
}

.icon-like-heart:before {
    content: "\e912";
}

.icon-alert1:before {
    content: "\e913";
}

.icon-analyse:before {
    content: "\e92b";
}

.icon-analytics-graphic:before {
    content: "\e9d8";
}

.icon-analytics:before {
    content: "\e9d7";
}

.icon-anchor:before {
    content: "\e917";
}

.icon-archieve:before {
    content: "\e918";
}

.icon-arrows:before {
    content: "\e919";
}

.icon-avatar-profile:before {
    content: "\e91a";
}

.icon-back:before {
    content: "\e91b";
}

.icon-backoffice:before {
    content: "\e91c";
}

.icon-bio:before {
    content: "\e91d";
}

.icon-brand:before {
    content: "\e92c";
}

.icon-calculator:before {
    content: "\e91f";
}

.icon-calendar:before {
    content: "\e920";
}

.icon-card-list-outline:before {
    content: "\e921";
}

.icon-card-outline:before {
    content: "\e922";
}

.icon-card:before {
    content: "\e923";
}

.icon-certificate:before {
    content: "\e924";
}

.icon-check:before {
    content: "\e925";
}

.icon-clock:before {
    content: "\e926";
}

.icon-pencil:before {
    content: "\e927";
}

.icon-comment-post:before {
    content: "\e931";
}

.icon-outline-like-heart:before {
    content: "\e929";
}

.icon-double-arrow-left:before {
    content: "\e94a";
}

.icon-double-arrow-right:before {
    content: "\e94b";
}

.icon-down:before {
    content: "\e92d";
}

.icon-close:before {
    content: "\e92e";
}

.icon-edit:before {
    content: "\e92f";
}

.icon-error:before {
    content: "\e930";
}

.icon-etc-open:before {
    content: "\e938";
}

.icon-etc:before {
    content: "\e932";
}

.icon-notepad:before {
    content: "\e933";
}

.icon-youtube:before {
    content: "\e934";
}

.icon-fall:before {
    content: "\e935";
}

.icon-favourite-circle:before {
    content: "\e936";
}

.icon-favourite:before {
    content: "\e937";
}

.icon-file:before {
    content: "\e9d6";
}

.icon-filter:before {
    content: "\e939";
}

.icon-form:before {
    content: "\e93a";
}

.icon-geolocation:before {
    content: "\e93b";
}

.icon-graph-bar:before {
    content: "\e93c";
}

.icon-graph-line:before {
    content: "\e93d";
}

.icon-growth:before {
    content: "\e93e";
}

.icon-help:before {
    content: "\e93f";
}

.icon-import:before {
    content: "\e940";
}

.icon-info:before {
    content: "\e941";
}

.icon-interation-text:before {
    content: "\e942";
}

.icon-language1:before {
    content: "\e943";
}

.icon-comment:before {
    content: "\e944";
}

.icon-card-posts:before {
    content: "\e945";
}

.icon-link-external:before {
    content: "\e946";
}

.icon-login:before {
    content: "\e947";
}

.icon-logout:before {
    content: "\e948";
}

.icon-minus:before {
    content: "\e949";
}

.icon-my-list-circle:before {
    content: "\e94c";
}

.icon-my-list:before {
    content: "\e9d4";
}

.icon-no-results:before {
    content: "\e9d5";
}

.icon-notification:before {
    content: "\e94d";
}

.icon-photo-post:before {
    content: "\e94e";
}

.icon-pin-pinterest:before {
    content: "\e94f";
}

.icon-pin:before {
    content: "\e950";
}

.icon-planner-list:before {
    content: "\e951";
}

.icon-outline-impressions:before {
    content: "\e952";
}

.icon-post-campaign:before {
    content: "\e953";
}

.icon-posts:before {
    content: "\e954";
}

.icon-priece:before {
    content: "\e955";
}

.icon-profile:before {
    content: "\e956";
}

.icon-publipost:before {
    content: "\e957";
}

.icon-save:before {
    content: "\e958";
}

.icon-search:before {
    content: "\e959";
}

.icon-back1:before {
    content: "\e95a";
}

.icon-share-network:before {
    content: "\e95b";
}

.icon-share:before {
    content: "\e95c";
}

.icon-social-facebook-circle:before {
    content: "\e95d";
}

.icon-social-facebook:before {
    content: "\e95e";
}

.icon-social-instagram-circle:before {
    content: "\e95f";
}

.icon-social-instagram:before {
    content: "\e960";
}

.icon-social-pinterest-circle:before {
    content: "\e961";
}

.icon-social-pinterest:before {
    content: "\e962";
}

.icon-social-twitter-circle:before {
    content: "\e963";
}

.icon-social-twitter:before {
    content: "\e964";
}

.icon-social-youtube-circle:before {
    content: "\e965";
}

.icon-social-youtube:before {
    content: "\e966";
}

.icon-symbol-airfluencers:before {
    content: "\e967";
}

.icon-airscore:before {
    content: "\e968";
}

.icon-topic-activism:before {
    content: "\e969";
}

.icon-topic-automotive:before {
    content: "\e96a";
}

.icon-topic-aviation:before {
    content: "\e96b";
}

.icon-topic-beauty:before {
    content: "\e96c";
}

.icon-topic-beer:before {
    content: "\e96d";
}

.icon-topic-business:before {
    content: "\e96e";
}

.icon-topic-construction:before {
    content: "\e96f";
}

.icon-topic-culture:before {
    content: "\e970";
}

.icon-topic-education:before {
    content: "\e971";
}

.icon-topic-entertainment:before {
    content: "\e972";
}

.icon-topic-environment:before {
    content: "\e973";
}

.icon-topic-fashion:before {
    content: "\e974";
}

.icon-topic-fitness:before {
    content: "\e975";
}

.icon-topic-gastronomy:before {
    content: "\e976";
}

.icon-topic-hair:before {
    content: "\e977";
}

.icon-topic-health:before {
    content: "\e978";
}

.icon-topic-kids:before {
    content: "\e979";
}

.icon-topic-life-family:before {
    content: "\e97a";
}

.icon-topic-lifestyle:before {
    content: "\e97b";
}

.icon-topic-literature:before {
    content: "\e97c";
}

.icon-topic-martial-art:before {
    content: "\e97d";
}

.icon-topic-music:before {
    content: "\e97e";
}

.icon-topic-nutrition:before {
    content: "\e97f";
}

.icon-topic-others:before {
    content: "\e980";
}

.icon-topic-pet:before {
    content: "\e981";
}

.icon-topic-politic:before {
    content: "\e982";
}

.icon-topic-sports:before {
    content: "\e983";
}

.icon-topic-stories:before {
    content: "\e984";
}

.icon-topic-technology:before {
    content: "\e985";
}

.icon-topic-travel:before {
    content: "\e986";
}

.icon-total-post:before {
    content: "\e987";
}

.icon-rocket:before {
    content: "\e988";
}

.icon-expand:before {
    content: "\e989";
}

.icon-up:before {
    content: "\e98a";
}

.icon-users-outline:before {
    content: "\e98b";
}

.icon-users1:before {
    content: "\e98c";
}

.icon-video-post:before {
    content: "\e98d";
}

.icon-view-first:before {
    content: "\e98e";
}

.icon-view-list:before {
    content: "\e98f";
}

.icon-view-mosaic:before {
    content: "\e990";
}

.icon-view:before {
    content: "\e991";
}

.icon-shared-economy:before {
    content: "\e992";
}

.icon-topic-calendar:before {
    content: "\e993";
}

.icon-impression:before {
    content: "\e994";
}

.icon-circle-plus:before {
    content: "\e995";
}

.icon-campaign:before {
    content: "\e996";
}

.icon-comments:before {
    content: "\e997";
}

.icon-group:before {
    content: "\e998";
}

.icon-favorite:before {
    content: "\e999";
}

.icon-clip:before {
    content: "\e99b";
}

.icon-clip1:before {
    content: "\e99c";
}

.icon-uniE997:before {
    content: "\e99d";
}

.icon-arrow-top:before {
    content: "\e99e";
}

.icon-filter-settings:before {
    content: "\e99f";
}

.icon-office:before {
    content: "\e9a0";
}

.icon-list:before {
    content: "\e9a1";
}

.icon-location:before {
    content: "\e9a2";
}

.icon-logout1:before {
    content: "\e9a3";
}

.icon-unshare:before {
    content: "\e9a4";
}

.icon-notifications:before {
    content: "\e9a5";
}

.icon-search-profile:before {
    content: "\e9a6";
}

.icon-settings:before {
    content: "\e9a7";
}

.icon-external-link:before {
    content: "\e9a8";
}

.icon-hexagon-user:before {
    content: "\e9a9";
}

.icon-tiktok-circle:before {
    content: "\e9aa";
}

.icon-plus:before {
    content: "\e9ab";
}

.icon-tooltip:before {
    content: "\e9ac";
}

.icon-clock-outline:before {
    content: "\e9ad";
}

.icon-tiktok:before {
    content: "\e9ae";
}

.icon-planner-list-add-influencer:before {
    content: "\e9af";
}

.icon-pin-location:before {
    content: "\e9b0";
}

.icon-info-circle:before {
    content: "\e9b1";
}

.icon-mobile-device:before {
    content: "\e9b2";
}

.icon-info1:before {
    content: "\e9b3";
}

.icon-image-square:before {
    content: "\e9b4";
}

.icon-folder-shared-line:before {
    content: "\e9b5";
}

.icon-trash:before {
    content: "\e9b6";
}

.icon-duplicate:before {
    content: "\e9b7";
}

.icon-arrow-next:before {
    content: "\e9b8";
}

.icon-download:before {
    content: "\e9b9";
}

.icon-twitter:before {
    content: "\e9ba";
}

.icon-circle-close:before {
    content: "\e9bb";
}

.icon-circle-check:before {
    content: "\e9bc";
}

.icon-calendar-month:before {
    content: "\e9bd";
}

.icon-arrow-share:before {
    content: "\e9be";
}

.icon-arrow-back:before {
    content: "\e9bf";
}

.icon-facebook:before {
    content: "\e9c0";
}

.icon-comment-balloon:before {
    content: "\e9c1";
}

.icon-eye:before {
    content: "\e9c2";
}

.icon-like-network:before {
    content: "\e9c3";
}

.icon-balloon:before {
    content: "\e9c4";
}

.icon-instagram:before {
    content: "\e9c5";
}

.icon-friction-talk-user:before {
    content: "\e9c6";
}

.icon-review-star-cursor:before {
    content: "\e9c7";
}

.icon-pin-location-1:before {
    content: "\e9c8";
}

.icon-make-up-star:before {
    content: "\e9c9";
}

.icon-single-user-favorite:before {
    content: "\e9ca";
}

.icon-thumbs-up-chat-message:before {
    content: "\e9cb";
}

.icon-baby-child:before {
    content: "\e9cc";
}

.icon-business-chart:before {
    content: "\e9cd";
}

.icon-business-chart-1:before {
    content: "\e9ce";
}

.icon-heart-favorite:before {
    content: "\e9cf";
}

.icon-lock:before {
    content: "\e9d0";
}

.icon-x-twitter:before {
    content: "\e9d1";
}

.icon-twitch:before {
    content: "\e9d2";
}

.icon-outline-brand:before {
    content: "\e9d3";
}

.icon-workspace:before {
    content: "\e9db";
}

.icon-send:before {
    content: "\e9dc";
}

.icon-ballon:before {
    content: "\e9dd";
}

.icon-email-check:before {
    content: "\e99a";
}

.icon-messages:before {
    content: "\e92a";
}