.air-textarea {
    max-width: 95%;
    padding: 2px;
    display: flex;
    align-items: flex-end   ;
    margin: 5px;

    border-radius: 8px;
    border: 1px solid #e2d8d8;
    background: #FFF;

    i {
        font-size: 28px;
        color: #676767;
    }

    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
    
    textarea {
        width: 100%;
        border: none;

        &:focus-visible {
            outline: none;
        }
    }
}