.messages-container {
  display: flex;
  height: 100vh;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;

  .create-chat {
    div {
      margin-right: 15px;
    }
  }

  .ant-radio-group {
    flex-direction: column;
  }

  .conversations-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    button {
      width: 100%;
    }

  }

  .conversations-list {
    width: 400px;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    background-color: white;

    .conversations-title {

      display: flex;
      align-items: center;
      margin-top: 28px;
      margin-left: 20px;
      font-size: 18px;
      color: #545454;
      font-weight: 500;

      i {
        margin-right: 20px;
        font-size: 25px;
      }
    }

    .conversation-item {
      display: flex;
      align-items: center;
      padding: 15px;
      cursor: pointer;
      transition: background-color 0.3s;
      border-top: 0.5px solid #C5C5C5;


      &:hover, &.selected {
        background-color: #f0f0f0;
      }

      .conversation-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #007bff;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
      }

      .conversation-info {
        flex-grow: 1;
        margin-left: 10px;
        color: #484848;

        .contact-name {
          display: flex;
          align-items: center;
          justify-content: space-between;

          i {
            color: #ADADAD;
            font-size: 18px;
          }
        }
        
        span {
          font-size: 12px;
          font-weight: 400;
        }

        h3 {
          margin: 0;
          font-size: 16px;
        }

        p {
          margin: 5px 0 0;
          font-size: 14px;
        }
      }

      .unread-badge {
        background-color: #007bff;
        color: white;
        border-radius: 50%;
        min-width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }
    }
  }

  .message-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;

    .message-header {
      padding: 20px;
      border-bottom: 1px solid #ddd;

      .contact-data {
        display: flex;
        align-items: center;

        &:first-child {
          color: #484848;
          font-weight: 700;
        }
        span {
          font-size: 12px;
          font-weight: 400;
          color: #676767;

          i {
            margin-right: 5px;
          }
        }
      }

      .contact-name {
        margin-left: 10px;
      }

      h2 {
        margin: 0;
      }
    }

    .message-list {
      flex-grow: 1;
      overflow-y: auto;
      padding: 20px;
    }

    .message-input {
      display: flex;
      padding: 20px;
      border-top: 1px solid #ddd;

      input {
        flex-grow: 1;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        margin-right: 10px;
      }

      button {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #0056b3;
        }
      }
    }

    .no-conversation-selected {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 18px;
      color: #666;
    }
  }
}
.msg-scroll {
  max-height: 500px;
  overflow-y: scroll;
}
.message-balloon {
  max-width: 500px;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 15px;
  
  &.current-user {
    align-self: flex-end;
    min-width: 300px;
    background-color: white;
    margin-left: auto;
  }
  
  &.other-user {
    align-self: flex-start;
    background-color: white;
    margin-right: auto;
  }

  .message-content {
    margin-bottom: 5px;
    word-wrap: break-word;

    a {
      color: #0000FF;
      text-decoration: underline;
    }
  }

  .message-info {
    display: flex;
    justify-content: flex-end;
    color: #888;
    font-size:  10px;
    margin-top: 10px;
  
    .sender {
      font-weight: 400;
    }
  }
}

.message-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100% - 100px); // Adjust based on your header and input heights
  padding: 10px;
}

.influencer-contact {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .influencer-contact-box {
    width: 500px;
    border-radius: 12px;
    background-color: white;
    padding: 30px 80px;
    display: flex;
    align-items: center;
    flex-direction: column;

    button {
      width: 100%;
    }
  }
}
