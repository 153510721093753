@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  --size: 40px;
  --border-width: calc(var(--size) * 0.1);
  
  width: var(--size);
  height: var(--size);
  border: var(--border-width) solid rgba(0, 0, 0, 0.1);
  border-top: var(--border-width) solid #ED276A;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}